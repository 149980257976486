

.App {
  text-align: center;
}

body{
  background-color: rgb(242, 229, 212);
  
}

.button {
  --bg: #000;
  --hover-bg: #C19A6B;
  --hover-text: #fff;
  color: #fff;
  cursor: pointer;
  border: 1px solid var(--bg);
  border-radius: 4px;
  padding: 0.8em 2em;
  background: var(--bg);
  transition: 0.2s;
}

.button:hover {
  color: var(--hover-text);
  transform: translate(-0.25rem, -0.25rem);
  background: var(--hover-bg);
  box-shadow: 0.25rem 0.25rem var(--bg);
}

.button:active {
  transform: translate(0);
  box-shadow: none;
}
/* 
#C19A6B Darker color
rgb(242, 229, 212) Brighter color
*/